<template>
  <v-card elevation="1" tile class="pa-5">
    <div class="subtitle-1 font-weight-bold mb-5">CONSULTAS POR RESPONDER</div>

    <DataTables
      :queries="queries"
      :loading="page.loading"
      :changePageFilter="true"
      @gotoQuery="gotoQuery"
      @changePage="changePage"
      @search="search"
    />
  </v-card>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentQuerySelectedMixin from "@/mixins/currentQuerySelected";
import currentDashboardMixin from "@/mixins/currentDashboard";

import queriesService from "@/services/queries";

import DataTables from "@/components/WFSite/Queries/DataTables";

export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentQuerySelectedMixin,
    currentDashboardMixin,
  ],
  components: { DataTables },
  data() {
    return {
      queries: [],
    };
  },
  methods: {
    async gotoQuery(selectedQueryInfo) {
      await this.loadQueryForWf(
        selectedQueryInfo.queryId,
        selectedQueryInfo.userIdentification,
        this.$route.name
      );
      this.gotoView("queryDetailInWf");
    },
    async getQueries(requiredPageNumber, filter) {
      this.initSignalLoading();

      let pageNumber = requiredPageNumber ? requiredPageNumber : 1;
      if (pageNumber > 1) this.loadDashboard();

      const getResult = await queriesService.getByWfStatusesV2(
        this.allConstants.dashboard.groups.ddcExecutive.queriesPending
          .wfStateType,
        pageNumber,
        15,
        "",
        filter
      );
      this.queries = getResult.data.queries;
      const currentPageData = this.currentPage;
      currentPageData.routerName = this.$route.name;
      currentPageData.pageNumber = pageNumber;
      currentPageData.totalPages = getResult.data.pagesTotal;
      currentPageData.totalRecords = getResult.data.recordsTotal;
      this.createOrUpdateCurrentPage(currentPageData);

      this.stopSignalLoading();
    },
    search(filter) {
      this.queries = [];
      this.getQueries(1, filter);
    },
    changePage(change) {
      this.queries = [];
      this.getQueries(change.toPageNumber, change.filter);
    },
  },
  beforeMount() {
    let pageNumber = 1;
    if (this.currentPage.routerName == this.$route.name)
      pageNumber = this.currentPage.pageNumber ?? 1;

    this.getQueries(pageNumber, []);
  },
};
</script>

<style lang="scss" scoped></style>
